import * as React from 'react'
import classNames from 'classnames'
import {DEPRECATED_PRODUCT_TYPES} from 'config'
import {useRouter} from 'next/router'

import {NvTabs} from '@invitae/ids-react'
import {useAnalyticsQueue} from '@invitae/nucleobase'
import {isServer} from '@invitae/nucleobase'
import {GeneV2Dto} from '@invitae/stargate'

import Breadcrumbs from 'components/Breadcrumbs'
import GeneralInfo from 'components/GeneDetail/GeneDetailContent/GeneralInfo'
import {VIEW_GENE_PAGE} from 'constants/analytics'
import {TABS_QUERY_NAME} from 'constants/links'
import {CUSTOM_ASSAY_INFO_GENE} from 'constants/specialCases'
import {getGeneByType, removeBasePath, scrollTo, scrollToTopSmooth} from 'utils/utils'

import GeneDetailContext from './GeneDetailContext'
import GeneSelectSection from './GeneSelectSection'
import {GeneTabContent} from './GeneTabContent'
import OrderTest from './OrderTest'

import styles from './GeneDetail.module.scss'

interface IGeneDetailProps {
  genes: GeneV2Dto[]
  featureFlags?: Record<string, boolean>
}

const GeneDetail = ({featureFlags, genes}: IGeneDetailProps) => {
  const {logEvent} = useAnalyticsQueue()
  const router = useRouter()

  const [isMobileOrderOpen, setIsMobileOrderOpen] = React.useState<boolean>(false)
  const [selectedGene, setSelectedGene] = React.useState<GeneV2Dto>()

  const otherTestsRef = React.useRef<null | HTMLDivElement>(null)

  // https://invitae.jira.com/browse/CELT-1914
  // We need to filter out product types that are no longer supported by Invitae,
  // so we will be filtering out these product types (e.g. ATM (/gene-20039))
  // logic for displaying a 404 page if there is only single product type for certain gene is in src/pages/_app.tsx line 60
  const availableGenes = React.useMemo(() => {
    if (featureFlags?.cx_ordering_carrier_nips_exception) return genes
    return genes.filter(gene => !DEPRECATED_PRODUCT_TYPES.includes(gene.productType || ''))
  }, [genes, featureFlags])
  const showTabs = React.useMemo(() => availableGenes.length > 1, [availableGenes])
  const sortedGenes = React.useMemo(
    () => availableGenes.sort((a, b) => a.productType!.toLowerCase().localeCompare(b.productType!.toLowerCase())),
    [availableGenes],
  )
  const tabs = React.useMemo(
    () =>
      showTabs
        ? sortedGenes.map((item: GeneV2Dto) => ({
            id: item.productType || '',
            text: item.productType || '',
          }))
        : [],
    [showTabs, sortedGenes],
  )

  const pageGene = React.useMemo(() => {
    const path = removeBasePath(router.asPath)
    const pageGenePart = path.split('-')[1]
    const pageGeneCode = pageGenePart.split('?')[0]
    const currentPageGene = genes.find(gene => {
      const geneCode = gene.code
      if (!geneCode) return
      if (geneCode.substring(0, geneCode.indexOf('.')).replace(/\D/g, '') === pageGeneCode) return gene
    })
    return currentPageGene
  }, [router.asPath, genes])

  const DEFAULT_TAB = React.useMemo(() => {
    const urlSearchParam = new URLSearchParams(router.asPath.split('?')[1])

    if (!urlSearchParam.get('tab') && pageGene) {
      return {id: pageGene.productType, text: pageGene.productType}
    }

    return tabs[0]
  }, [tabs, pageGene, router.asPath])

  const onRoutePush = React.useCallback(
    (path: string) => {
      router.push(path, undefined, {shallow: true})
    },
    [router],
  )

  const [pathname, searchParam] = React.useMemo(() => router.asPath.split('?'), [router.asPath])

  const onTypeChange = React.useCallback(
    (geneType: string) => {
      setSelectedGene(getGeneByType(genes, geneType))
      scrollToTopSmooth()
    },
    [setSelectedGene, genes],
  )

  const scrollToOtherTests = React.useCallback(() => {
    if (otherTestsRef.current) {
      scrollTo(otherTestsRef.current.offsetTop)
    }
  }, [otherTestsRef.current])

  const isCustomAssayEnabled = React.useMemo(() => {
    return selectedGene?.code ? selectedGene.code.includes(CUSTOM_ASSAY_INFO_GENE) : false
  }, [selectedGene])

  const contextValue = {
    isCustomAssayEnabled,
    productType: selectedGene?.productType ?? '',
    scrollToOtherTests,
  }

  React.useEffect(() => {
    if (!isServer()) {
      logEvent({
        eventName: VIEW_GENE_PAGE,
        eventProperties: {
          referrer: document.referrer,
        },
      })
    }
  }, [logEvent])

  React.useEffect(() => {
    if (!selectedGene && DEFAULT_TAB?.id) {
      setSelectedGene(getGeneByType(sortedGenes, DEFAULT_TAB.id))
    }
  }, [DEFAULT_TAB, selectedGene, sortedGenes])

  if (!selectedGene) return null

  return (
    <div className={classNames(styles.root, {[styles.rootHideForMobile]: isMobileOrderOpen})}>
      <div className="nv-container">
        <div className={styles.breadcrumbsContainer}>
          <Breadcrumbs currentTitle={selectedGene.name ?? ''} />
        </div>
        <div className={styles.geneDataWrapper}>
          <GeneDetailContext.Provider value={contextValue}>
            <NvTabs.Container
              defaultTabId={DEFAULT_TAB?.id || ''}
              id={TABS_QUERY_NAME}
              onRoutePush={onRoutePush}
              onTabChange={onTypeChange}
              pathname={pathname}
              searchParam={searchParam}
              // TODO: withSearchParamsControl not accept `false`. It will be fixed in a new version of ids-react
              withSearchParamsControl={showTabs || undefined}
            >
              <div className={styles.geneDetailWrapper}>
                <GeneralInfo
                  assayNotes={selectedGene.assayNotes ?? ''}
                  name={selectedGene.name ?? ''}
                  showTabs={showTabs}
                />
                {showTabs && <NvTabs.Header tabs={tabs} />}
                <GeneTabContent genes={genes} selectedGene={selectedGene} showTabs={showTabs} />
              </div>
              <GeneSelectSection
                genes={sortedGenes}
                isMobileOrderOpen={isMobileOrderOpen}
                setIsMobileOrderOpen={() => setIsMobileOrderOpen(prev => !prev)}
                shouldOpenModal={showTabs}
              />
            </NvTabs.Container>
          </GeneDetailContext.Provider>
        </div>
      </div>
      <OrderTest
        customRef={otherTestsRef}
        optTests={selectedGene.optTests ?? []}
        tests={selectedGene.groupTests ?? []}
      />
    </div>
  )
}

export default GeneDetail
